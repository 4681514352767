html {
	scroll-behavior: smooth;
	font-family: 'Noto Serif JP', serif;
}

body .btn {
	font-size: 17px;
}

body .btn-large {
	font-size: 20px;
	font-weight: 500 !important;
	line-height: 50px;
	height: 50px;
	padding: 0 20px;
	text-transform: uppercase;
}

#square .sq-content {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

#square {
	width: 100%;
	padding-bottom: 100%;
	position: relative;
	/* background-color: #2980b9; */
}

body .btn,
body .btn-large,
body .btn-small {
	font-family: 'Montserrat', sans-serif;
	text-decoration: none;
	color: #fff;
	background-color: #0f75bd;
	text-align: center;
	letter-spacing: 0.5px;
	transition: background-color 0.2s ease-out;
	cursor: pointer;
	box-shadow: none;
	border-radius: 10px;
	font-weight: 300;
	text-transform: none;
}

body .btn:hover,
body .btn-large:hover,
body .btn-small:hover {
	background-color: #85d80a !important;
	-webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14),
		0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
		0 3px 1px -1px rgba(0, 0, 0, 0.2);
	color: white;
}

.btn:focus,
.btn-large:focus,
.btn-small:focus,
.btn-floating:focus {
	background-color: #0f75bd !important;
}

footer {
	font-family: 'Montserrat', sans-serif;
}

.container {
	/* margin: 0 auto;
	max-width: 1200px;
	width: 95%; */
	margin: 0 auto;
	max-width: 100%;
	width: 100%;
	padding: 0 2.25%;
}

.container h1 {
	font-weight: 300;
	font-size: 2.5rem;
	color: #0f75bd;
}

.container h2 {
	font-size: 2rem;
}

.container h3 {
	font-size: 1.4rem;
	margin: 10px 0;
}

.container h4 {
	font-weight: bold;
	font-size: 2rem;
}

a {
	color: #0f75bd;
	transition: all 0.2s ease-out;
	font-size: 17px;
}

a:hover {
	color: #85d80a;
}

.container p {
	font-size: 17px;
}

nav ul a {
	font-family: 'Roboto', sans-serif;
	font-size: 1rem;
	padding: 0;
	margin: 0 15px;
	line-height: 25px;
	color: #404040;
	text-transform: uppercase;
	font-weight: 300;
}

nav ul a:hover {
	background-color: transparent;
}

.container footer li {
	display: inline-block;
	margin: 5px 20px;
}

.container footer li a {
	color: #fff;
	text-transform: uppercase;
	-webkit-transition: all 0.35s;
	transition: all 0.35s;
}

.container footer li a:hover {
	color: black;
}

::placeholder {
	color: #000;
}

.headBg {
	background-image: url('/assets/images/FoxWeeksPETS-hero.jpg');
	min-height: 400px;
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: 100%;
}

nav,
nav .nav-wrapper i,
nav a.sidenav-trigger,
nav a.sidenav-trigger i {
	display: inline;
	float: right;
	color: #0f75bd;
	height: 45px;
	line-height: 42px;
	font-size: 45px;
	border-radius: 5px;
	border: 1px solid #0f75bd;
}

nav .sidenav-trigger {
	margin: 30px 10px 0px;
}

.sidenav li > a {
	font-size: 17px;
	color: #0f75bd;
	text-align: center;
	/* border-bottom: 1px solid; */
}

.sidenav li > a:hover {
	background-color: transparent;
	color: #85d80a;
}

input:not([type]):focus:not([readonly]),
input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='url']:not(.browser-default):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default):focus:not([readonly]),
input[type='number']:not(.browser-default):focus:not([readonly]),
input[type='search']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
	border-bottom: 1px solid #85d80a;
	-webkit-box-shadow: 0 1px 0 0 #85d80a;
	box-shadow: 0 1px 0 0 #85d80a;
}

input:not([type]):focus:not([readonly]) + label,
input[type='text']:not(.browser-default):focus:not([readonly]) + label,
input[type='password']:not(.browser-default):focus:not([readonly]) + label,
input[type='email']:not(.browser-default):focus:not([readonly]) + label,
input[type='url']:not(.browser-default):focus:not([readonly]) + label,
input[type='time']:not(.browser-default):focus:not([readonly]) + label,
input[type='date']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime-local']:not(.browser-default):focus:not([readonly])
	+ label,
input[type='tel']:not(.browser-default):focus:not([readonly]) + label,
input[type='number']:not(.browser-default):focus:not([readonly]) + label,
input[type='search']:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
	color: #85d80a;
}

.clip-circle {
	clip-path: circle(50%);
}

.petCirle {
	border-radius: 50%;
	overflow: hidden;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.heart {
	cursor: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/9632/heart.png'),
		auto;
	-webkit-transition: all 0.35s;
	transition: all 0.35s;
}

.petCircleItem {
	margin: 30px 0;
}

.ssIcon a {
	-webkit-transition: background-color 0.3s;
	transition: background-color 0.3s;
	color: #282828;
	width: 50px;
	height: 50px;
	vertical-align: middle;
	line-height: 44px;
	border-radius: 50%;
	font-size: 25px;
	margin: 10px;
	text-align: center;
	border: 1px solid;
	display: inline-block;
}

.fbIcon:hover {
	background-color: #3a5794;
	border: 1px solid #3a5794;
	color: white;
}

.twIcon:hover {
	background-color: #1c9cea;
	border: 1px solid #1c9cea;
	color: white;
}

.igIcon:hover {
	background-color: #da2f69;
	border: 1px solid #da2f69;
	color: white;
}

.eIcon:hover {
	background-color: #85d80a;
	border: 1px solid #85d80a;
	color: white;
}

/*---------------*/
/***** Lily *****/
/*---------------*/

/* Common style */
.grid figure {
	position: relative;
	float: left;
	overflow: hidden;
	margin: 10px 1%;
	/* min-width: 320px;
	max-width: 480px;
	max-height: 360px; */
	width: 100%;
	/* background: #3085a3; */
	text-align: center;
	cursor: pointer;
	border-radius: 20px;
}

.grid figure img {
	position: relative;
	display: block;
	min-height: 100%;
	max-width: 100%;
	opacity: 0.8;
}

.grid figure figcaption {
	padding: 2em;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
	pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
	z-index: 1000;
	text-indent: 200%;
	white-space: nowrap;
	font-size: 0;
	opacity: 0;
}

.grid figure h2 {
	/* word-spacing: -0.15em; */
	font-weight: 400;
	font-size: 1.4rem;
	font-family: 'Montserrat', sans-serif;
}

.grid figure h2 span {
	font-weight: 800;
}

.grid figure h2,
.grid figure p {
	margin: 0;
}

.grid figure p {
	font-size: 16px;
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
}

figure.effect-lily img {
	max-width: none;
	width: -webkit-calc(100% + 50px);
	width: calc(100% + 50px);
	opacity: 1;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: translate3d(-40px, 0, 0);
	transform: translate3d(-40px, 0, 0);
}

figure.effect-lily figcaption {
	text-align: left;
}

figure.effect-lily figcaption > div {
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 20px;
	width: 100%;
	height: 40%;
	background-color: rgba(0, 0, 0, 0.5);
}

figure.effect-lily h2,
figure.effect-lily p {
	-webkit-transform: translate3d(0, 10px, 0);
	transform: translate3d(0, 10px, 0);
}

figure.effect-lily h2 {
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;
}

figure.effect-lily p {
	color: rgba(255, 255, 255, 1);
	opacity: 0;
	-webkit-transition: opacity 0.2s, -webkit-transform 0.35s;
	transition: opacity 0.2s, transform 0.35s;
}

figure.effect-lily:hover img,
figure.effect-lily:hover p {
	opacity: 1;
}

figure.effect-lily:hover img,
figure.effect-lily:hover h2,
figure.effect-lily:hover p {
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

figure.effect-lily:hover p {
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
	-webkit-transition-duration: 0.35s;
	transition-duration: 0.35s;
}

/*---------------*/
/***** End Lily *****/
/*---------------*/

hr.fadeEnd {
	border: 0;
	height: 1px;
	background-image: linear-gradient(
		to right,
		rgba(0, 0, 0, 0),
		rgba(0, 0, 0, 0.75),
		rgba(0, 0, 0, 0)
	);
}

.valignAdj {
	display: block;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.noBgCollapsable {
	box-shadow: none;
	border: 0;
	font-size: 17px;
	color: #444;
}

.noBgCollapsable li li:first-child {
	padding-top: 0;
}

.noBgCollapsable li li li {
	list-style-type: disc;
	margin-left: 30px;
	padding-left: 0;
}

.noBgCollapsable li li {
	border: 0;
	padding: 10px 0 0 40px;
}

.noBgCollapsable li {
	border-bottom: 1px solid #ddd;
	-webkit-transition: 0.25s;
	transition: 0.25s;
}

.noBgCollapsable li:last-child {
	border-bottom: 0px;
}

.noBgCollapsableHead {
	display: block;
	border: 0;
	font-weight: bold;
}

.noBgCollapsableHead:hover {
	background-color: rgb(242, 242, 242);
}

.noBgCollapsableBody {
	border: 0;
	padding: 10px 15px 25px 15px;
}

.discStyle li {
	list-style-type: disc !important;
	padding-left: 5px !important;
	margin-left: 45px;
}

.numStyle {
	padding: 0px !important;
}

.numStyle li {
	padding-left: 5px !important;
	margin-left: 45px;
}

/* Tablet style m-size */
@media only screen and (min-width: 601px) {
	.headBg {
		min-height: 430px;
	}
}

/* Desktop style l-size*/
@media only screen and (min-width: 993px) {
	.headBg {
		min-height: 650px;
	}

	.container h1 {
		font-size: 3.5rem;
	}

	.container {
		padding: 0 6.25%;
	}

	figure.effect-lily figcaption > div {
		height: 25%;
	}

	.valignAdj {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}
}

/* Desktop style xl-size*/
@media only screen and (min-width: 1900px) {
	.headBg {
		min-height: 850px;
	}
}
